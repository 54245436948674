


 .formSignUp {
    width: 100%;
}

.formSignUp input:not(:first-child){
    border: 1px solid black !important;
}

.formSignUp label::after{
    color: #1956CB;
}

.formSignUp div div:nth-child(2) label:after{
    color: black;
}

.add-mb-20{
    margin-bottom: 20px;
   
}


.css-b8ldur-Input{
    width: 320px;
}

.css-b8ldur-Input input{
    width: 320px;
}

.css-dyr6gj-container {
    width: 385px;
    border: 1px solid black !important;
   
}

.cursor-pointer{
    cursor: pointer;
}



.addressWrapper div div:nth-child(1) {
    width: 410px;
    
}


.addressWrapper div div input {
 
    padding: 0.67857143em 1em !important;
}

.state-dropdown{
    background: white;
    width: 100% !important;
    position: absolute;
    top: 110%;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    z-index: 100;
    max-height: 200px;
    overflow-y: scroll;
    flex-wrap: nowrap !important;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.2);
}

.state-dropdown div{
    padding: 3px;
    width: 100% !important;
}

.state-dropdown div:hover{
    background-color: rgba(91, 90, 87, 0.124);
    cursor:  pointer;
    width: 100% !important;
}

.half-input{
    width: 48% !important;
}

.text-justify{
    text-align: justify !important;
}


@media only screen and (max-width: 768px)  {  
    
    .addressWrapper div div:nth-child(1) {
        width: 100%;
    }

    
    .bottom-30{
        display: none;
    }

    .half-input{
        width: 100% !important;
    }
}

.pac-target-input{
    background-repeat: no-repeat !important;
    background-size: 15px !important;
}

@media only screen and (min-width: 768px)  {  
    
    .addressWrapper div{
        display: flex;
        justify-content: space-between;
  
        flex-wrap: wrap;
      
        
    }


}



